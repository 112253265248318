<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="container">
      <div class="column spacer">
        <h1>Alle informatie over Webhosting</h1>
        <h2>Onze standaard pakketten</h2>
        <Prices :items="data.staticData.prices.webhostingPrices"/>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Prices from '@/components/Prices.vue';

export default {
  name: 'Webhosting',
  components: {
    Breadcrumbs,
    Prices,
  },
  setup() {
    const data = inject('data');

    return {
      // store
      data,
    };
  },
}
</script>